<template>
  <v-container>
    <v-row justify="center">
      <v-toolbar min-width="100%" dark color="primary">
        <v-card-title>Draft </v-card-title>
      </v-toolbar>
      <v-col cols="12" md="8" lg="12" style="margin-top: 10px">
        <v-row style="margin-top: 10px; border: 1px solid grey">
          <v-col cols="3" class="text-right" style="margin-right: 20px">
            Filter by date
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="start_date"
              label="Start Date"
              type="date"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="end_date"
              label="End Date"
              type="date"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" md="8" lg="12">
        <DataTableSSR
          apiEndPoint="/draft/"
          :headers="headers"
          :instantLoad="true"
          :queryParams="{
            start_date: start_date,
            end_date: end_date,
          }"
        >
          <template #[`item.title`]="{ item }">
            {{ item.title }}
          </template>

          <template #[`item.created_at`]="{ item }">
            {{ new Date(item.created_at).toLocaleString() }}
          </template>
          <template #[`item.action`]="{ item }">
            <v-dialog max-width="700px" v-model="item.dialogNewFee">
              <template v-slot:activator="{ attrs, on }">
                <v-row>
                  <v-btn
                    v-bind="attrs"
                    color="primary"
                    class="ma-4"
                    dark
                    v-on="on"
                    outlined
                    :disabled="!item.composeEnabled"
                    >Compose Now</v-btn
                  >
                  <v-switch v-model="item.composeEnabled"></v-switch>
                </v-row>
              </template>
              <draft-compose :item="item"></draft-compose>
            </v-dialog>
          </template>
        </DataTableSSR>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DataTableSSR from "../../../components/global/DataTableSSR.vue";
import DraftCompose from "./DraftCompose.vue";
export default {
  components: { DataTableSSR, DraftCompose },
  props: ["item"],

  data() {
    return {
      start_date: null,
      end_date: null,
      headers: [
        {
          text: "Title",
          value: "title",
        },

        {
          text: "Created",
          value: "created_at",
        },
        {
          text: "Action",
          value: "action",
          align: "center",
        },
      ],
      composeEnabled: true,
    };
  },
};
</script>

<style></style>
